import React, { useContext, useState } from 'react';
import TextInput from '../../CMFW/input/TextInput';
import { ListDialects } from '../../CMFW/layout/ListDialects';
import { TranslationStatusSelect } from './TranslationStatusSelect';
import styled from 'styled-components';
import KeyContext from '../../CMFW/context/KeyContext';
import { IRefIds } from '../../constants/refs';
import TextEditor from '../../CMFW/input/TextEditor';
import ErrorDisplay from '../common/ErrorDisplay';
import { FormControlLabel, Switch } from '@material-ui/core';
import HTMLSourceInput from '../../CMFW/input/HTMLSourceInput';
import AIReviewPanel from '../../modules/AIReview/components/AIReviewPanel';
import AIReviewIcon from '../../modules/AIReview/components/AIReviewIcon';

type IProps = {
  keyName: string;
  refId: IRefIds;
};

const Translations: React.FC<IProps> = ({ keyName, refId }) => {
  const parentKey = useContext(KeyContext);
  const [useHTMLSourceEditor, setUseHTMLSourceEditor] = useState(true);

  const handleChangeUseHTMLSourceEditor = () => {
    setUseHTMLSourceEditor(!useHTMLSourceEditor);
  };

  const hasHTMLSourceEditor = (): boolean => {
    switch (refId) {
      case IRefIds.lessonTitle:
      case IRefIds.panelTitle:
      case IRefIds.tableLiterals:
      case IRefIds.cultural:
      case IRefIds.paragraph:
      case IRefIds.trainer:
        return true;
      default:
        return false;
    }
  };

  const renderEditor = () => {
    switch (refId) {
      case IRefIds.lessonTitle:
      case IRefIds.panelTitle:
      case IRefIds.tableLiterals:
      case IRefIds.trainer:
        return (
          <TextEditor
            keyName={'text'}
            config={{
              toolbar: [['Undo', 'Redo'], ['RemoveFormat']],
              toolBarInline: [['lang_target', 'lang_glossary']],
            }}
            autoGrow
          />
        );
      case IRefIds.cultural:
        return (
          <TextEditor
            keyName={'text'}
            config={{
              toolbar: [['Undo', 'Redo'], ['RemoveFormat']],
              toolBarInline: [['Bold', 'Italic']],
            }}
            autoGrow
          />
        );
      case IRefIds.paragraph:
        return (
          <TextEditor
            keyName={'text'}
            config={{
              toolbar: [['Undo', 'Redo'], ['RemoveFormat']],
              toolBarInline: [['Bold', 'Italic', 'lang_target', 'lang_glossary', 'wVocInline', 'wPanelInline']],
            }}
            autoGrow
          />
        );
      default:
        return <TextInputStyled keyName={'text'} multiline />;
    }
  };

  const renderCATEditor = () => {
    return (
      <WrapperBorder>
        <HTMLSourceInput keyName={'text'} />
      </WrapperBorder>
    );
  };

  return (
    <Container>
      {hasHTMLSourceEditor() && (
        <FormControlLabelStyled
          control={
            <Switch
              checked={useHTMLSourceEditor}
              onChange={handleChangeUseHTMLSourceEditor}
              color="secondary"
              size="small"
            />
          }
          label={'CAT mode'}
        />
      )}
      <ListDialects keyName={keyName} key={refId}>
        <Wrapper>
          <ErrorDisplay />
          <AIReviewPanel hidable />
          <Row>
            <TranslationStatusSelect key={parentKey} />
            <AIReviewIcon />
            {hasHTMLSourceEditor() && useHTMLSourceEditor ? renderCATEditor() : renderEditor()}
          </Row>
        </Wrapper>
      </ListDialects>
    </Container>
  );
};

export default Translations;

const TextInputStyled = styled(TextInput)`
  && {
    margin: 2px 0;
    width: 100%;
  }
  & .MuiInputBase-root {
    padding: 0 0 0 4px;
  }
`;

const Container = styled.div`
  margin-top: 20px;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  && {
    margin-left: 5px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

const WrapperBorder = styled.div`
  width: 100%;
  border-color: #0000003b;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 4px;

  &:hover {
    border-color: #282828;
  }
`;
